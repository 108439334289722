import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkout";
import { useParams, useSearchParams } from "react-router-dom";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_test_51PHnGxHO9vQ2tZOzotXuNkhxHyILEV6ESTu9aIPREsH1cju3t4BwGStedvjikwu2TGIpNXYJfv4NDOgVqTnoTRnM00FQU5w2oE");

export default function Payment(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [searchParams] = useSearchParams();
  const { accepted, id } = useParams();
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("https://us-central1-loopgolfnowapi.cloudfunctions.net/api/create-payment-intent-for-invite", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        amount: props.amount,
        invite_id: searchParams.get('inviteId'),
        booking_id: id,
        phone: props.phone,
        resId: props.resId
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);
  
  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#1840CE',
    }
  };
  const options = {
    clientSecret,
    appearance,
  };
  
  return (
    <div style={{marginTop: 40}}>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}
