import logo from './logo.svg';
import './App.css';
import { Button, Checkbox, Col, Form, Input, Layout, Row, Spin, notification, Card } from "antd";
import { Routes, Route, Outlet, Link, useParams, useSearchParams } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { UserOutlined, CalendarOutlined, ClockCircleOutlined, SmileOutlined } from '@ant-design/icons';
import { createContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import Checkout from "./components/checkout";
import Payment from "./components/Payment";
import ShareTeaTime from "./components/ShareTeeTime";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/join/:id" element={<JoinMeeting/>}/>
        <Route path="/share/:id?/:accepted?" element={<ShareTeaTime/>}/>
      </Routes>
    </BrowserRouter>
  );
}
const Context = createContext({ name: 'Default' });

function JoinMeeting() {
  const { id } = useParams()
  const [spinning, setSpinning] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  console.log('id', id)
  const onFinish = (values) => {
    console.log('Success:', values);
    setSpinning(true)
    fetch('https://us-central1-loopgolfnowapi.cloudfunctions.net/api/join-tea-time',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "name": values.phone,
          "phone": values.phone,
          "note": values.note || "",
          "id": id
        })
      })
      .then(response => response.json())
      .then(data => {
        setSpinning(false)
        api.success({
          message: 'Tee Time Joined',
          description: 'You have successfully Joined the tee time.'
        });
      })
      .catch(error => console.error(error));
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const contextValue = useMemo(() => ({ name: 'Ant Design' }), []);
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <div className="App" style={{ margin: 'auto', paddingTop: 200 }}>
        <h1 style={{ fontSize: 70, marginBottom: 100 }}>JOIN TEE TIME</h1>
        <div style={{ margin: 'auto' }}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{
              span: 20,
            }}
            style={{
              maxWidth: 500,
              margin: 'auto'
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Full Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Name!',
                },
              ]}
            >
              <Input/>
            </Form.Item>
            
            <Form.Item
              label="phone"
              name="phone"
              rules={[
                {
                  required: true,
                  message: 'Please input your phone!',
                },
              ]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              label="Note"
              name="note"
              rules={[
                {
                  required: false,
                  message: 'Please enter your note',
                },
              ]}
            >
              <Input.TextArea/>
            </Form.Item>
            
            <Button type="primary" htmlType="submit" block style={{ width: '50%', marginTop: 50, height: 40 }}>
              Join
            </Button>
          </Form>
        </div>
        <Spin spinning={spinning} fullscreen/>
      </div>
    </Context.Provider>
  );
}
