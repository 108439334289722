import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { SmileOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { useParams, useSearchParams } from "react-router-dom";

export default function CheckoutForm() {
  const { id } = useParams()
  const stripe = useStripe();
  const elements = useElements();
  const { accepted } = useParams();
  
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const openNotification = (message) => {
    api.open({
      message: 'Invited',
      description: message,
      icon: <SmileOutlined style={{ color: '#108ee9' }} />,
    });
  };
  
  useEffect(() => {
    if(message === 'Payment succeeded!'){
      api.success({
        message: 'Payment Success',
        description: 'Your payment is processed and being reviewed. You will get the confirmation soon.'
      });
    }
  }, [message]);
  
  useEffect(() => {
    if (!stripe) {
      return;
    }
    
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    
    if (!clientSecret) {
      return;
    }
    
    const acceptInvite = (values) => {
      fetch('https://us-central1-loopgolfnowapi.cloudfunctions.net/api/accept-tee-time-invite',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "id": id,
            "inviteId":accepted,
            "action":"accept"
          })
        })
        .then(response => response.json())
        .then(data => {
         // openNotification(data.message)
        })
        .catch(error => console.error(error));
    };
    
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
         // acceptInvite();
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    
    setIsLoading(true);
    
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `https://pay.quatrofi.com/share/${id}/${searchParams.get('inviteId')}`,
      },
    });
    
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    
    setIsLoading(false);
  };
  
  console.log('message', message)
  
  const paymentElementOptions = {
    layout: "tabs"
  }
  
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {!message &&<>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
      </>}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
